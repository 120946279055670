import * as React from "react"
import { graphql } from "gatsby"
import { CheckCircleIcon, ChevronRightIcon, HomeIcon } from '@heroicons/react/24/solid'

import Layout from "../components/layout"
import Seo from "../components/seo"

const ProfileTemplate = ({
  data: { site, markdownRemark: post },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`
  const pages = [
    { name: 'Search', href: '/search', current: false },
    { name: 'Profile', href: '', current: true },
  ]

  const renderSocialLinks = (links) => {
    if (!links) {
      return null;
    }
  
    // List of allowed domains
    const allowedDomains = ['.com', '.net', '.org', /* add other domains as needed */];
  
    const linksArray = typeof links === 'string' ? links.split(' ').filter(link => link.trim() !== '') : links;
  
    // Filter and process the links
    const processedLinks = linksArray.map(link => {
      try {
        link = link.toLowerCase();
        if (!link.startsWith('http://') && !link.startsWith('https://')) {
          link = 'https://' + link;
        }
  
        const url = new URL(link);
        const isAllowedDomain = allowedDomains.some(domain => url.hostname.includes(domain));
        if (!isAllowedDomain) {
          return null;
        }
  
        const domainParts = url.hostname.split('.');
        const siteName = domainParts.length > 2 ? domainParts[1] : domainParts[0];
        const formattedName = siteName.charAt(0).toUpperCase() + siteName.slice(1);
  
        return { link, formattedName };
      } catch (error) {
        return null;
      }
    }).filter(linkObj => linkObj !== null);
  
    // Check if there are any valid links to render
    if (processedLinks.length === 0) {
      return null;
    }
  
    return (
      <>
        <h2 className="text-md mt-4 font-semibold">External Links:</h2>
        <ul className="my-3 indent-3 list-disc list-inside">
          {processedLinks.map((linkObj, index) => (
            <li key={index}>
              <a href={linkObj.link} title={linkObj.formattedName} target="_blank" rel="noopener noreferrer">
                {linkObj.formattedName}
              </a>
            </li>
          ))}
        </ul>
      </>
    );
  };
  
  
  
  
  
  
  return (
    <Layout location={location} title={siteTitle}>

        <div className="bg-gray-900 p-5 mx-1 md:py-5">
          <div className="container mx-auto">
            <nav className="flex" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-3">
                <li>
                  <div>
                    <a href="/" className="text-gray-400 hover:text-white">
                      <HomeIcon className="h-3 w-3 flex-shrink-0" aria-hidden="true" />
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                {pages.map((page) => (
                  <li key={page.name}>
                    <div className="flex items-center">
                      <ChevronRightIcon className="h-3 w-3 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <a
                        href={page.href}
                        className="ml-4 text-xs font-medium text-gray-500 hover:text-white"
                        aria-current={page.current ? 'page' : undefined}
                      >
                        {page.name}
                      </a>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>

        <div className="container mx-auto p-5 md:rounded-md mb-5 text-white bg-gray-800 text-sm md:my-5">
          <div className="container mx-auto">
            <div className="mb-3">
              <img src={post.frontmatter.profile_img} alt={`${post.frontmatter.name} Profile`} className="w-full md:w-44 md:h-44 rounded-md" />
            </div>
            <div className="">
              <h1 className="text-2xl font-semibold">{post.frontmatter.name}</h1>
              <div className="mb-2">{post.frontmatter.pronoun}</div>
              <div className="text-lg font-medium">{post.frontmatter.tagline}</div>
              <div className="mb-2">{post.frontmatter.status}</div>
              {post.frontmatter.professional_professions && <div className=""><strong>Professions:</strong> {post.frontmatter.professional_professions}</div>}
              {post.frontmatter.professional_projects && <div className=""><strong>Projects:</strong> {post.frontmatter.professional_projects}</div>}
              {post.frontmatter.professional_genres && <div className=""><strong>Genres:</strong> {post.frontmatter.professional_genres}</div>}
              {renderSocialLinks(post.frontmatter.social_links)}
            </div>
            <div
              className="mt-5 text-white text-sm profile-details"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
        </div>
        <div className="text-gray-500 text-xs container mx-auto">
          <p>This listing was automatically created by CREWZIP. This listing is not currently maintained by, endorsed by or affiliated with {post.frontmatter.name}.</p>
          <p>{post.frontmatter.name} is an unclaimed page. Only {post.frontmatter.name} may claim this page.</p>
        </div>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={`${post.frontmatter.name} - ${post.frontmatter.status}`}
    />
  )
}

export default ProfileTemplate

export const query = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }, fileAbsolutePath: { regex: "/content/profile/" }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        name
        date(formatString: "MMMM DD, YYYY")
        tagline
        pronoun
        status
        professional_professions
        professional_projects
        professional_genres
        social_links
        profile_img
      }
    }
  }
`

